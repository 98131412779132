import React from "react";
import Header from "../common/header";

function Transactions() {
  return (
    <div>
      <Header />
      <div className="body-content body-content-lg">
        <div className="container">
          <div className="page-header">
            <div className="page-header-title page-header-item">
              <h3>Transactions</h3>
            </div>
          </div>

          <div className="transaction-section pb-15">
            <div className="section-header">
              <h2>Today</h2>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-2.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Brenda Davis</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det negative-number">
                  -$185.00
                </div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-cm-logo-1.png" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Appstore Purchase</h3>
                    <p>App Purchase</p>
                  </div>
                </div>
                <div className="transaction-card-det negative-number">
                  -$159.99
                </div>
              </a>
            </div>
          </div>

          <div className="transaction-section pb">
            <div className="section-header">
              <h2>Yesterday</h2>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-5.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Martin Neely</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det">+$185.00</div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-6.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Mary McMillian</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det">+$2573.00</div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-1.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Debbie Wright</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det negative-number">
                  -$780.00
                </div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-7.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Kenneth</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det negative-number">
                  -$573.00
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Transactions;
