import React from "react";
import Header from "../common/header";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div>
      <Header />
      <div className="body-content body-content-lg">
        {" "}
        <div className="container">
          <div className="add-card section-to-header mb-30">
            <div className="add-card-inner">
              <div className="add-card-item add-card-info">
                <p>Your Wallet</p>
                <h3>₹1,450.50</h3>
              </div>
              <div
                className="add-card-item add-balance"
              >
               <p>Total Saving</p>
                <h3>₹450.50</h3>
              </div>
            </div>
          </div>

          <div className="option-section mb-15">
            <div className="row gx-3">
              <div className="col pb-15">
                <div className="option-card option-card-violet">
                <Link to="/mobilerecharge">
                 
                    <div className="option-card-icon">
                      <i className="flaticon-phone"></i>
                    </div>
                    <p>Mobile Recharge</p>
                 
                </Link>
                </div>
              </div>
            
              <div className="col pb-15">
                <div className="option-card option-card-blue">
                  <a href="my-cards.html">
                    <div className="option-card-icon">
                      <i className="flaticon-credit-card"></i>
                    </div>
                    <p>DTH Recharge</p>
                  </a>
                </div>
              </div>
              
            </div>
          </div>

          <div className="feature-section mb-15">
            <div className="row gx-3">
              <div className="col col-sm-6 pb-15">
                <div className="feature-card feature-card-red">
                  <div className="feature-card-thumb">
                    <i className="flaticon-income"></i>
                  </div>
                  <div className="feature-card-details">
                    <p>Recharge Amount</p>
                    <h3>₹4485.50</h3>
                  </div>
                </div>
              </div>
              <div className="col col-sm-6 pb-15">
                <div className="feature-card feature-card-blue">
                  <div className="feature-card-thumb">
                    <i className="flaticon-expenses"></i>
                  </div>
                  <div className="feature-card-details">
                    <p>Refferal Amount</p>
                    <h3>₹95.50</h3>
                  </div>
                </div>
              </div>
              <div className="col col-sm-6 pb-15">
                <div className="feature-card feature-card-violet">
                  <div className="feature-card-thumb">
                    <i className="flaticon-invoice"></i>
                  </div>
                  <div className="feature-card-details">
                    <p>Total Recharge</p>
                    <h3>77</h3>
                  </div>
                </div>
              </div>
              <div className="col col-sm-6 pb-15">
                <div className="feature-card feature-card-green">
                  <div className="feature-card-thumb">
                    <i className="flaticon-savings"></i>
                  </div>
                  <div className="feature-card-details">
                    <p>Total Savings</p>
                    <h3>₹285.00</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="transaction-section pb-15">
            <div className="section-header">
              <h2>Transactions</h2>
              <div className="view-all">
                <Link to="/transactions">View All</Link>
              </div>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-2.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>9090908080</h3>
                    <p>VI <b className="progress-card-amount">success</b></p> 
                  </div>
                </div>
                <div className="transaction-card-det negative-number">
                  -$185.00
                </div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-cm-logo-1.png" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Appstore Purchase</h3>
                    <p>App Purchase</p>
                  </div>
                </div>
                <div className="transaction-card-det">-$159.99</div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-1.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Martin Neely</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det">+$170.00</div>
              </a>
            </div>
            <div className="transaction-card mb-15">
              <a href="transaction-details.html">
                <div className="transaction-card-info">
                  <div className="transaction-info-thumb">
                    <img src="assets/images/user-3.jpg" alt="user" />
                  </div>
                  <div className="transaction-info-text">
                    <h3>Mary McMillian</h3>
                    <p>Transfer</p>
                  </div>
                </div>
                <div className="transaction-card-det">+$2573.00</div>
              </a>
            </div>
          </div>

          <div className="monthly-bill-section pb-15">
            <div className="section-header">
              <h2>Monthly Bills</h2>
              <div className="view-all">
                <a href="monthly-bills.html">View All</a>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-6 pb-15">
                <div className="monthly-bill-card monthly-bill-card-green">
                  <div className="monthly-bill-thumb">
                    <img src="assets/images/cm-logo-1.png" alt="logo" />
                  </div>
                  <div className="monthly-bill-body">
                    <h3>
                      <a href="#">Envato.com</a>
                    </h3>
                    <p>Debit Services Subscribtion</p>
                  </div>
                  <div className="monthly-bill-footer monthly-bill-action">
                    <a href="#" className="btn main-btn">
                      Pay Now
                    </a>
                    <p className="monthly-bill-price">$99.99</p>
                  </div>
                </div>
              </div>
              <div className="col-6 pb-15">
                <div className="monthly-bill-card monthly-bill-card-green">
                  <div className="monthly-bill-thumb">
                    <img src="assets/images/cm-logo-2.png" alt="logo" />
                  </div>
                  <div className="monthly-bill-body">
                    <h3>
                      <a href="#">Oban.com</a>
                    </h3>
                    <p>Credit Services Subscribtion</p>
                  </div>
                  <div className="monthly-bill-footer monthly-bill-action">
                    <a href="#" className="btn main-btn">
                      Pay Now
                    </a>
                    <p className="monthly-bill-price">$75.00</p>
                  </div>
                </div>
              </div>
              <div className="col-6 pb-15">
                <div className="monthly-bill-card monthly-bill-card-green">
                  <div className="monthly-bill-thumb">
                    <img src="assets/images/cm-logo-3.png" alt="logo" />
                  </div>
                  <div className="monthly-bill-body">
                    <h3>
                      <a href="#">Nezox.com</a>
                    </h3>
                    <p>Internet Monthly Subscribtion</p>
                  </div>
                  <div className="monthly-bill-footer monthly-bill-action">
                    <a href="#" className="btn main-btn">
                      Pay Now
                    </a>
                    <p className="monthly-bill-price">$50.50</p>
                  </div>
                </div>
              </div>
              <div className="col-6 pb-15">
                <div className="monthly-bill-card monthly-bill-card-green">
                  <div className="monthly-bill-thumb">
                    <img src="assets/images/cm-logo-4.png" alt="logo" />
                  </div>
                  <div className="monthly-bill-body">
                    <h3>
                      <a href="#">Depan.com</a>
                    </h3>
                    <p>Depan Monthly Subscribtion</p>
                  </div>
                  <div className="monthly-bill-footer monthly-bill-action">
                    <a href="#" className="btn main-btn">
                      Pay Now
                    </a>
                    <p className="monthly-bill-price">$100.99</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

         

          <div className="latest-news-section pb-15">
            <div className="section-header">
              <h2>Latest News</h2>
              <div className="view-all">
                <a href="blogs.html">View All</a>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-6 pb-15">
                <div className="blog-card">
                  <div className="blog-card-thumb">
                    <a href="#">
                      <img src="assets/images/blogs/blog-1.jpg" alt="blog" />
                    </a>
                  </div>
                  <div className="blog-card-details">
                    <ul className="blog-entry">
                      <li>Smith Rob</li>
                      <li>15 Jan, 2021</li>
                    </ul>
                    <h3>
                      <a href="#">
                        Financing Loans Available To Small Businesses
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-6 pb-15">
                <div className="blog-card">
                  <div className="blog-card-thumb">
                    <a href="#">
                      <img src="assets/images/blogs/blog-2.jpg" alt="blog" />
                    </a>
                  </div>
                  <div className="blog-card-details">
                    <ul className="blog-entry">
                      <li>John Doe</li>
                      <li>13 Jan, 2021</li>
                    </ul>
                    <h3>
                      <a href="#">
                        Need Financial Help To Open Your New Business
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
