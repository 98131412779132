import React, { useState, useEffect, useRef } from "react";
import Header from "../common/header";
import "./mobilerecharge.css";

import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

function MobileRecharge() {
  const [apiResponse, setApiResponse] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const playNotificationSound = useRef(false);

  // Function to toggle notification sound
  const toggleNotificationSound = () => {
    playNotificationSound.current = true;
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Replace this with your actual API POST request
      // Example: const response = await axios.post("/your-api-endpoint", requestData);
      const response = { data: "API response data" };

      setApiResponse(response.data);
      setIsSuccess(true);

      // Trigger the notification sound
      toggleNotificationSound();
      toast.success("Recharge successful! Your transaction ID is 11221122");
    } catch (error) {
      console.error("API Error:", error);

      // Handle the error here
      // You can set an error state variable and display an error message to the user
    }
  };

  // Use useEffect to play the notification sound when isSuccess changes to true
  useEffect(() => {
    if (playNotificationSound.current) {
      // Play the notification sound here (replace with your sound file path)
      const audio = new Audio("/notification-sound.mp3");
      audio.play();

      // Reset the flag to prevent repeated sound playing
      playNotificationSound.current = false;
    }
  }, [isSuccess]);

  return (
    <div>
      <Header />
      <div className="body-content">
        <div className="container">
          <div className="page-header">
            <div className="page-header-title page-header-item">
              <h3>Mobile Recharge</h3>
            </div>
          </div>

          <div className="payment-list pb-30">
            <div className="radio-button-container">
              <input
                type="radio"
                id="prepaid"
                name="plan"
                value="prepaid"
                checked
              />
              <label htmlFor="prepaid">Prepaid</label>
              <input
                type="radio"
                id="postpaid"
                name="plan"
                value="postpaid"
              />
              <label htmlFor="postpaid">Postpaid</label>
            </div>

            <div className="form-group mb-15">
              <label htmlFor="input3" className="form-label">
                Mobile Number
              </label>
              <input
                type="number"
                className="form-control"
                id="input3"
                placeholder="Enter Mobile Number"
                contacts
              />
            </div>

            <div className="form-group mb-15">
              <label htmlFor="input3" className="form-label">
                Operator
              </label>
              <select className="form-control">
                <option>Select Operator</option>
                <option value="Airtel"> Airtel</option>
                <option value="BSNL"> BSNL</option>
                <option value="Jio">Jio</option>
                <option value="VI">VI</option>
              </select>
            </div>

            <Link to={'/plans'}><button
              type="button"
              // onClick={handleSubmit}
              className="btn main-btn main-btn-lg full-width"
            >
              Proceed
            </button></Link>

            {/* {isSuccess && (
              <div className="notification">
                <p>Recharge successful! Your transaction ID is: {apiResponse}</p>
              </div>
            )} */}

            {/* You can also add error handling logic and display error messages here */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileRecharge;
