import React from "react";
import Header from "../common/header";

function Notifications() {
  return (
    <div>
      <Header />
      <div className="body-content">
        <div className="container">
          <div className="page-header">
            <div className="page-header-title page-header-item">
              <h3>Notifications</h3>
            </div>
          </div>
          <div className="notification-section pb-15">
            <div className="notification-item">
              <div className="notification-card">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#notificationModal"
                >
                  <div className="notification-card-thumb">
                    <i className="flaticon-bell"></i>
                  </div>
                  <div className="notification-card-details">
                    <h3>Payment Received</h3>
                    <p>15/01/2021 05:05 AM</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-card">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#notificationModal"
                >
                  <div className="notification-card-thumb">
                    <i className="flaticon-bell"></i>
                    <span className="option-badge">5</span>
                  </div>
                  <div className="notification-card-details">
                    <h3>New Message</h3>
                    <p>14/01/2021 01:50 PM</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-card">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#notificationModal"
                >
                  <div className="notification-card-thumb">
                    <i className="flaticon-bell"></i>
                  </div>
                  <div className="notification-card-details">
                    <h3>Password Changed</h3>
                    <p>12/01/2021 10:010 AM</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-card">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#notificationModal"
                >
                  <div className="notification-card-thumb">
                    <i className="flaticon-bell"></i>
                  </div>
                  <div className="notification-card-details">
                    <h3>Updates To Our Privacy Policy</h3>
                    <p>12/01/2021 12:59 AM</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-card">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#notificationModal"
                >
                  <div className="notification-card-thumb">
                    <i className="flaticon-bell"></i>
                  </div>
                  <div className="notification-card-details">
                    <h3>Money Has Been Sent</h3>
                    <p>01/01/2021 01:00 PM</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Notifications;
