import React, { useState, useEffect, useRef } from "react";
import Header from "../common/header";
import "./plan.css";
import { Link } from "react-router-dom";

function Plandetail() {

    return (
        <div>
            <Header />
            <div className="body-content">
                <div className="container">
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Vi prepaid</h3>
                        </div>
                    </div>

                    <div className="payment-list pb-30">
                        <div className="authentication-form pb-15">
                            <div class="mt-4 p-5 text-light text-center rounded">
                                <div className="row">
                                    <div className="col-sm"></div>
                                    <div className="col-sm">
                                    <div className="col-sm">
                                    <img src="assets/images/user-2.jpg" alt="user" width={80}/>
                                    <p>Paying for VI</p>
                                    <h2 className="mt-4 mb-4">Total amount ₹ 499</h2>

                                        <div class="alert alert-secondary" role="alert">
                                            <p>Recharge for Vi </p>
                                            <p>Prepaid2117 </p>
                                            <p>(+91 8374748383) </p>

                                            </div>
                                           <Link to={'/paysuccess'}> <div className="btn btn-primary">Pay ₹ 499</div></Link>
                                    </div>
                                    </div>
                                    <div className="col-sm">
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plandetail;
