import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Login from "./components/login/Login";
import Dashboard from './components/dashboard/Dashboard';
import Transactions from './components/transactions/transactions';
import NotFound from './NotFound';
import Notifications from './components/notifications/notifications';
import MobileRecharge from './components/recharge/mobilerecharge';
import Plan from './components/plans/Plan';
import Plandetail from './components/plans/Plandetail';
import Paysuccess from './components/paysuccess/Paysuccess';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/transactions" element={<Transactions />}></Route>
        <Route path="/notifications" element={<Notifications />}></Route>
        <Route path="/mobilerecharge" element={<MobileRecharge />}></Route>
        <Route path="/plans" element={<Plan />}></Route>
        <Route path="/plandetail" element={<Plandetail />}></Route>
        <Route path="/paysuccess" element={<Paysuccess />}></Route>
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    );
}

export default App;
