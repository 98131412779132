import React from "react";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

function Header() {
  return (
    <div>
        <div class="header-bg header-bg-1"></div>
      
        <div class="fixed-top">
            <div class="appbar-area sticky-black">
                <div class="container">
                    <div class="appbar-container">
                        <div class="appbar-item appbar-actions">
                            <div class="appbar-action-item">
                                <a href="#" class="appbar-action-bar" data-bs-toggle="modal" data-bs-target="#sidebarDrawer"><i class="flaticon-menu"></i></a>
                            </div>
                        </div>
                        <div class="appbar-item appbar-brand me-auto">
                            <a href="index.html">
                                <img src="assets/images/logo.png" alt="logo" class="main-logo" />
                                <img src="assets/images/logo-hover.png" alt="logo" class="hover-logo" />
                            </a>
                        </div>
                        <div class="appbar-item appbar-options">
                            <div class="appbar-option-item appbar-option-notification">
                                <Link to="/notifications"><i class="flaticon-bell"></i></Link>
                                <span class="option-badge">5</span>
                            </div>
                            <div class="appbar-option-item appbar-option-profile">
                                <a href="settings.html"><img src="assets/images/profile.jpg" alt="profile" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toaster toastOptions={{ duration: 4000 }} />
      <Sidebar />
      <Footer />
    </div>
  );
}

export default Header;
