import React, { useState, useEffect, useRef } from "react";
import Header from "../common/header";
import "./plan.css";
import { Link } from "react-router-dom";

function Plan() {

    return (
        <div>
            <Header />
            <div className="body-content">
                <div className="container">
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Vi prepaid</h3>
                        </div>
                    </div>

                    <div className="payment-list pb-30">
                        <div className="authentication-form pb-15">
                            
                            <div className="row mt-5">
                            
                                <div className="col-sm">
                                <div class="mt-2 mb-4 text-light rounded">
                                <div className="text-center mt-4 mb-4">
                                            
                                            <div className="transaction-info-text justify-content-center">
                                            <img src="assets/images/user-2.jpg" alt="user" width={80} />
                                                <h3>9090908080</h3>
                                                <p>VI Prepaid | Lucknow</p>
                                            </div>
                                        </div>
                                <div className="row">
                                    

                                <div className="col-sm"></div>
                                    <div className="col-sm">
                                        <lable>Select Plan</lable>
                                        <input type="text" className="form-control" placeholder="Search for a plan or enter amount" />
                                    </div>
                                    <div className="col-sm">
                                    </div>
                                </div>


                            </div>
                                    {/* Tab-selector */}
                                    <div className="tab-selector">
                                        {/* Tab-selector-list */}
                                        <ul className="tab-selector-list">
                                            <li className="active" data-tab-list={1}>
                                                <button>Special Offers</button>
                                            </li>
                                            <li data-tab-list={2}>
                                                <button>Top Up</button>
                                            </li>
                                            <li data-tab-list={3}>
                                                <button>Full Talktime</button>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* Tab-selector-list */}
                                    {/* Tab-selector-details */}
                                    <div className="tab-selector-details">
                                        <div className="tab-selector-details-item active" data-tab-details={1}>
                                            <div className="row gx-3">

                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="tab-selector-details-item" data-tab-details={2}>
                                        <div className="row gx-3">

<div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
</div>
                                        </div>
                                        <div className="tab-selector-details-item" data-tab-details={3}>
                                        <div className="row gx-3">

                                        <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
                                                <div className="col-12 pb-15 ">
                                                <Link to={'/plandetail'}><div className="monthly-bill-card monthly-bill-card-green">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="monthly-bill-body text-start">
                                                                    <h3><b>₹ 499</b></h3>
                                                                    <h3><small><b>Calls -</b> Unlimited</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>28 Days</b></h3>
                                                                    <h3><small>Validity</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                            <div className="monthly-bill-body text-start">
                                                                    <h3><b>3 GB/Day</b></h3>
                                                                    <h3><small>Data</small></h3>
                                                                </div>
                                                            </div>
                                                            <div className="col text-end">
                                                                <div className="monthly-bill-footer monthly-bill-action">
                                                                    <b className="btn btn-primary btn-md">Apply</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row">
                                                            <div className="col-sm righttext text-start"><small>Cost per day - ₹ 17.8 </small></div>
                                                            <div className="col-sm text-end"><small>Details </small></div>
                                                            
                                                        </div>

                                                        {/* <div className="monthly-bill-body">
                                                            <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
                                                        </div> */}

                                                    </div></Link>
                                                </div>
</div>
                                        </div>
                                    </div>
                                    {/* Tab-selector-details */}
                                    {/* Tab-selector */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plan;
