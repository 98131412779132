import React from "react";

import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="app-navbar">
        <div className="container">
          <div className="navbar-content ">
            <div className="navbar-content-item">
              <NavLink to="/dashboard" activeClassName="active">
                <i className="flaticon-house"></i> Dashboard
              </NavLink>
            </div>
            
            <div className="navbar-content-item">
              <a href="#">
                <i className="flaticon-credit-card"></i>
                Refferals
              </a>
            </div>
            <div className="navbar-content-item">
              <NavLink to="/transactions" activeClassName="active">
                <i className="flaticon-invoice"></i> Transactions
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
