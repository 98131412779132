import React, { useState } from "react";
import { APP_NAME } from "../../constants";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth, messaging } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";


function Login() {
  const navigate = useNavigate();
   const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);


  const requestNotificationPermission = async () => {
    try {
      await messaging.requestPermission();
      console.log('Notification permission granted.');
      const token = await messaging.getToken();
      console.log('FCM token:', token);
      // Store this token on your server or associate it with the user.
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignup();
        }
      });
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+91" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Error Sending OTP");
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        toast.success("OTP Verified successfully!");
        
        navigate('/dashboard');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Invalid OTP Please Try again!");
      });
  }

  return (
    <div>
      <div className="header-bg header-bg-1"></div>
      <div className="fixed-top">
        <div className="appbar-area sticky-black">
          <div className="container">
            <div className="appbar-container">
              <div className="appbar-item appbar-actions">
                <div className="appbar-action-item">
                  <a href="#" className="back-page">
                    <i className="flaticon-left-arrow"></i>
                  </a>
                </div>
              </div>
              <div className="appbar-item appbar-page-title mx-auto">
                <h3>Sign In</h3>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="body-content">
        <div className="container">
          <div className="page-header">
            <div className="page-header-title page-header-item">
              <h3>Sign In To {APP_NAME}</h3>
            </div>
          </div>
          <Toaster toastOptions={{ duration: 4000 }} />
          <div id="recaptcha-container"></div>
          <div className="authentication-form pb-15">
          {!showOTP && (<div>
            <div className="form-group pb-15">
              <label>Mobile Number</label>
              <div className="input-group">
                <input
                  type="number"
                  name="ph"
                  className="form-control"
                  required
                  placeholder="Enter your mobile number"
                  value={ph}
                  onChange={(e) => setPh(e.target.value)}
                />
                

                <span className="input-group-text">
                  <i className="flaticon-user-picture"></i>
                </span>
              </div>
             
            </div>
            <button
              onClick={onSignup}
              className="btn main-btn main-btn-lg full-width mb-10"
            >
              {loading && (
                    <CgSpinner size={25} className="mt-1 animate-spin" />
                  )}
              Send OTP
            </button>
          </div>
          )}
            {showOTP && (
              <div>
                <div className="form-group pb-15">
                  <p>OTP sent to: {ph} <a onClick={onOTPVerify} >
                                    <i className="icofont-ui-edit"></i>
                                </a></p>
                  <label>OTP</label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="phoneNumber"
                      className="form-control"
                      required
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <span className="input-group-text">
                      <i className="flaticon-user-picture"></i>
                    </span>
                  </div>
                  <b className="transaction-card-det negative-number">
                  
                  </b>
                </div>
                <button
                  onClick={onOTPVerify}
                  className="btn main-btn main-btn-lg full-width mb-10"
                >
                  {loading && (
                    <CgSpinner size={25} className="mt-1 animate-spin" />
                  )}
                  Verify OTP
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
