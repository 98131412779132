import React, { useState, useEffect, useRef } from "react";
import Header from "../common/header";
import { Link } from "react-router-dom";

function Paysuccess() {

    return (
        <div>
            <Header />
            <div className="body-content">
                <div className="container">
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Vi prepaid</h3>
                        </div>
                    </div>

                    <div className="payment-list pb-30">
                        <div className="authentication-form pb-15">
                            <div class="mt-4 p-5 text-light text-center rounded">
                                <div className="row">
                                    <div className="col-sm"></div>
                                    <div className="col-sm">
                                    <div className="col-sm">
                                    <h2 className="mt-4 mb-4">Thankyou</h2>

                                    <img src="assets/images/blogs/success.png" alt="user" width={80}/>
                                    <h2 className="mt-4 mb-4">Payment Successful</h2>

                                    </div>
                                    </div>
                                    <div className="col-sm">
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Paysuccess;
