import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging'; // Import getMessaging

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBh5ZjDCsVZ7f0rEl-xKfshwQ5GQidkkxg",
  authDomain: "prepaysaver.firebaseapp.com",
  projectId: "prepaysaver",
  storageBucket: "prepaysaver.appspot.com",
  messagingSenderId: "120536952996",
  appId: "1:120536952996:web:66855f0e94dcd2aa3cb631",
  measurementId: "G-492RT1Q8ET"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app); // Initialize Firebase Cloud Messaging

export { auth , messaging};