import React from "react";

function Sidebar() {
  return (
    <div>
       <div className="modal fade" id="sidebarDrawer" tabindex="-1" aria-labelledby="sidebarDrawer" aria-hidden="true">
            <div className="modal-dialog side-modal-dialog">
                <div className="modal-content">
                    <div className="modal-header sidebar-modal-header">
                        <div className="sidebar-profile-info">
                            <div className="sidebar-profile-thumb">
                                <img src="assets/images/profile.jpg" alt="profile" />
                            </div>
                            <div className="sidebar-profile-text">
                                <h3>Brenda Davis</h3>
                                <p><a href="tel:1545-8880">1545 8880</a></p>
                            </div>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="sidebar-profile-wallet">
                        <div className="add-card-info">
                            <p>Your Wallet</p>
                            <h3>$1,450.50</h3>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="sidebar-nav">
                            <div className="sidebar-nav-item">
                                <h3>Oban Menu</h3>
                                <ul className="sidebar-nav-list">
                                    <li><a href="index.html" className="active"><i className="flaticon-house"></i> Home</a></li>
                                    <li><a href="pages.html"><i className="flaticon-invoice"></i> Pages</a></li>
                                    <li><a href="components.html"><i className="flaticon-menu-1"></i> Components</a></li>
                                    <li><a href="my-cards.html"><i className="flaticon-credit-card"></i> My Cards</a></li>
                                    <li><a href="settings.html"><i className="flaticon-settings"></i> Settings</a></li>
                                    <li><a href="contact-us.html"><i className="flaticon-call-center-agent"></i> Contact Us</a></li>
                                    <li><a href="#"><i className="flaticon-logout"></i> Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Sidebar;
