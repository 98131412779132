import React from "react";

import Header from "./components/common/header";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <Header />
      <div className="body-content">
        <div className="container">
          <section className="error-page-section pb-15">
            <div className="container">
              <div className="error-page-content">
              <img src="assets/images/404.png" alt="404" />

                <h2>Error 404: Page Not found</h2>
                <p>The page you were looking for could not be found.</p>
                <Link to="/dashboard" className="btn main-btn">
                  Go To Homepage
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
